var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet" }, [
    _c(
      "div",
      {
        staticClass:
          "kt-portlet__body kt-ribbon kt-ribbon--flag kt-ribbon--ver kt-portlet__body--fit-y kt-margin-b-40",
        class: {
          "kt-ribbon--primary": _vm.student.googleEmail,
          "kt-ribbon--warning": !_vm.student.googleEmail,
        },
      },
      [
        _vm._m(0),
        _c("div", { staticClass: "kt-widget kt-widget--user-profile-4" }, [
          _c("div", { staticClass: "kt-widget__head mt-5 mb-3" }, [
            _c(
              "div",
              { staticClass: "kt-widget__media" },
              [
                _c("StudentPortfolioAvatar", {
                  staticStyle: { width: "96px", height: "96px" },
                  attrs: { student: _vm.student, rounded: true },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "kt-widget__content" }, [
              _c("div", { staticClass: "kt-widget__section pt-4" }, [
                _c(
                  "div",
                  { staticClass: "kt-widget__footer" },
                  [
                    !_vm.encryptionEnabled
                      ? _c(
                          "router-link",
                          {
                            staticClass: "kt-widget__username",
                            attrs: {
                              to: {
                                name: "StudentInfo",
                                params: {
                                  studentEnrollmentId:
                                    _vm.student.studentEnrollmentId,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.student.lastName) +
                                ", " +
                                _vm._s(_vm.student.firstName) +
                                " "
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass: "kt-widget__username",
                            attrs: {
                              to: {
                                name: "StudentInfo",
                                params: {
                                  studentEnrollmentId:
                                    _vm.student.studentEnrollmentId,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.student.maskedStudentName) + " "
                            ),
                          ]
                        ),
                    _c(
                      "div",
                      {
                        staticClass: "kt-widget__description",
                        class: !_vm.student.googleEmail ? "mb-3" : "",
                      },
                      [
                        _vm.student.googleEmail && !_vm.encryptionEnabled
                          ? _c(
                              "span",
                              { attrs: { title: "student.googleEmail" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.student.googleEmail.split("@")[0]
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("br"),
                        !_vm.encryptionEnabled
                          ? _c("span", { staticClass: "mb-3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.student.extStudentId.substring(0, 3)
                                  ) +
                                  "- " +
                                  _vm._s(
                                    _vm.student.extStudentId.substring(3, 6)
                                  ) +
                                  "- " +
                                  _vm._s(
                                    _vm.student.extStudentId.substring(6, 9)
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c("br"),
                        !_vm.student.googleEmail
                          ? _c("span", { staticClass: "kt-font-light" }, [
                              _vm._v(" Missing google email address "),
                            ])
                          : _vm._e(),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-label-primary btn-wide w-100",
                            attrs: {
                              to: {
                                name: "StudentInfo",
                                params: {
                                  studentEnrollmentId:
                                    _vm.student.studentEnrollmentId,
                                },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-user" }),
                            _vm._v(" PORTFOLIO "),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "kt-widget__description pt-2" }, [
                      _c("span", [
                        _vm._v("Homeroom: " + _vm._s(_vm.student.homeRoom)),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-widget__button mt-3" }, [
                      !_vm.encryptionEnabled
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.student.primaryGuardian) + " "
                            ),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.student.phoneNumber) + " "),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "kt-ribbon__target",
        staticStyle: { top: "0", right: "20px", height: "45px" },
      },
      [
        _c("span", { staticClass: "kt-ribbon__inner" }),
        _c("i", { staticClass: "socicon-google mt-1 kt-font-light" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }