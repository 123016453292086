<template>
<div class="kt-portlet">
    <div
        class="kt-portlet__body kt-ribbon kt-ribbon--flag kt-ribbon--ver kt-portlet__body--fit-y kt-margin-b-40"
        :class="{
            'kt-ribbon--primary': student.googleEmail,
            'kt-ribbon--warning': !student.googleEmail,
        }"
    >
        <div
            class="kt-ribbon__target"
            style="top: 0; right: 20px; height: 45px;"
        >
            <span class="kt-ribbon__inner" />
            <i class="socicon-google mt-1 kt-font-light" />
        </div>

        <div class="kt-widget kt-widget--user-profile-4">
            <div class="kt-widget__head mt-5 mb-3">
                <div class="kt-widget__media">
                    <StudentPortfolioAvatar
                        style="width: 96px; height: 96px;"
                        :student="student"
                        :rounded="true"
                    />
                </div>
                <div class="kt-widget__content">
                    <div class="kt-widget__section pt-4">
                        <div class="kt-widget__footer">
                            <router-link
                                v-if="!encryptionEnabled"
                                :to="{
                                    name: 'StudentInfo',
                                    params: {studentEnrollmentId: student.studentEnrollmentId}
                                }"
                                class="kt-widget__username"
                            >
                                {{ student.lastName }}, {{ student.firstName }}
                            </router-link>
                            <router-link
                                v-else
                                :to="{
                                    name: 'StudentInfo',
                                    params: {studentEnrollmentId: student.studentEnrollmentId}
                                }"
                                class="kt-widget__username"
                            >
                                {{ student.maskedStudentName }}
                            </router-link>

                            <div
                                class="kt-widget__description"
                                :class="!student.googleEmail ? 'mb-3' : ''"
                            >
                                <span
                                    v-if="student.googleEmail && !encryptionEnabled"
                                    title="student.googleEmail"
                                >
                                    {{ student.googleEmail.split('@')[0] }}
                                </span>

                                <br>
                                <span v-if="!encryptionEnabled" class="mb-3">
                                    {{ student.extStudentId.substring(0, 3) }}-
                                    {{ student.extStudentId.substring(3, 6) }}-
                                    {{ student.extStudentId.substring(6, 9) }}
                                </span>
                                <br>
                                <span
                                    v-if="!student.googleEmail"
                                    class="kt-font-light"
                                >
                                    Missing google email address
                                </span>

                                <router-link
                                    :to="{
                                        name: 'StudentInfo',
                                        params: {studentEnrollmentId: student.studentEnrollmentId}
                                    }"
                                    class="btn btn-label-primary btn-wide w-100"
                                >
                                    <i class="la la-user" />
                                    PORTFOLIO
                                </router-link>
                            </div>

                            <div class="kt-widget__description pt-2">
                                <span>Homeroom: {{ student.homeRoom }}</span>
                            </div>

                            <div class="kt-widget__button mt-3">
                                <span v-if="!encryptionEnabled">
                                    {{ student.primaryGuardian }} <br>
                                    {{ student.phoneNumber }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import StudentPortfolioAvatar from './StudentPortfolioAvatar.vue';

export default {
    name: 'StudentListItem',
    components: {
        StudentPortfolioAvatar,
    },
    props: {
        student: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            role: (state) => state.user.school.role,
        }),
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        extStudentId() {
            const splitId = this.student.extStudentId.split('');
            const result = [];
            let i = 0;
            for (i = 0; i < splitId.length; i += 1) {
                if (i != 0 && i % 3 == 0) {
                    result.push('-');
                    result.push(splitId[i]);
                } else {
                    result.push(splitId[i]);
                }
            }
            return result.join('');
        },
    },
    methods: {
    },

};
</script>

<style scoped>

.btn-wide {
    white-space: nowrap;
}

.kt-widget__img_sg {
    border-radius: 50%;
    width: 90px;
    min-height: 90px;
}

.email-label {
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: clip;
}

div.kt-widget__description span {
    width: 100%;
    text-overflow: clip;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
}
</style>
